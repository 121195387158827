@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("fonts.css");

@layer base {
  html {
    font-family: Rubik, sans-serif;
    max-width: 100%;
  }
}

#root {
  white-space: pre-line;
}
